// import logo from './logo.svg';
import './App.css';
import logo from './logo.svg';

function App() {
  return (
    <div className="App">
      <div className="App-body">
        <img src={logo} className="App-logo" alt="logo" />
        <h1>ELD Capital LLC</h1>
        <p>
          ELD is a private investment fund. At ELD we integrate international
          corporate finance and artificial intelligence to facilitate
          active strategic management of our portfolio.
        </p>
        <p>
          We follow a macro thesis investment backdrop to establish
          discretionary positions. Within this framework we deploy a range of
          proprietary quantitative methods to the risk and position space.
          We also service key instruments to provide market liquidity.
          Our quantitative methods include machine and deep learning.
        </p>
        <div className="Disclaimer">
          <p>
            The materials on this website are for illustration and
            discussion purposes only and do not constitute an offering.
          </p>
        </div>
      </div>
    </div>
  );
}

export default App;
